<template>
    <div>
        <div class="list">
            <label>{{label}}</label>
            <div class="list-item" v-for="( item, index ) in list" :key="`key-${index}-${listVueKey}`">
                <ListItem 
                    :data-object="item"
                    :list-index="parseInt( index )"
                    :list-total-count="list.length"
                    :sample-object="object"
                    :hooks="hooks"
                    v-on:list-edit="listElementChanged"
                    v-bind:is-settings="isSettings"
                    v-bind:class="editableClass"
                    :disabled="!editable"
                    v-once
                />
            </div> 
            <div class="list-add-item" v-if="editable">
                <FormulateInput
                    type="button"
                    label="Add new item"                   
                    @click="addListElement"
                />
            </div>
        </div>
    </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import deepClone from '@/services/deep-clone';

library.add( faPlus, faAngleUp );

export default {
    name: "List",
    components: {
        ListItem: () => import( './ListItem' )
    },
    props: {
        label: {
            type: [String,Number],
            default:''
        },
        value: {
            type: [Array, Object],
            default: () => { return []; }
        },
        // a sample object of list element
        object: {
            type:Object,
            default: () => {}
        },
        isSettings: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            list: [],
            hooks: {
                remove: this.deleteListEl,
                moveUp: this.upListEl,
                moveDown: this.downListEl
            },
            listVueKey: Date.now()
        }
    },
    mounted(){
        this.globalListToLocalList();
    },
    methods: {
        deleteListEl( index ) {
            this.list.splice( index, 1 );

            this.listChanged();
        },
        upListEl( index ) {
            this.moveListEl( index, -1 );

            this.listChanged();
        },
        downListEl( index ) {
            this.moveListEl( index, 1 );

            this.listChanged();
        },
        moveListEl( curIndex, delta ) {
            var newIndex = curIndex + delta;

            if ( newIndex < 0 || newIndex == this.list.length ) return; //Already at the top or bottom.

            let element = this.list[curIndex];
            this.list.splice(curIndex, 1);
            this.list.splice(newIndex, 0, element);

            this.listVueKey = Date.now();
        },
        // i - index in list
        // v - value
        // k - key/name of the property in list object
        listElementChanged( i, v, k ){
            this.list[ i ][ k ].value = v;

            this.listChanged();
        },
        editableClass(){
            if( !this.editable )
            {
                return "not-editable";
            }
            return "";
        },
        listChanged() {
            this.$emit( 'list-changed', this.list );
        },
        addListElement(){
            this.list.push( JSON.parse( JSON.stringify( this.object ) ) );

            this.listChanged();
        },
        // keeps object in the list up to date regarding the object sample provided, appends new fields or removes old ones when needed
        updateListElementsFromOjbect(){ 
            let keys = Object.keys( this.object );
            for( let i = 0; i < keys.length; i++ ){
                let currentKey = keys[i];

                for( let j = 0; j < this.list.length; j++ ){
                    // append new fields if they are not present on current list element
                    if( !this.list[j][currentKey] ){
                        if( typeof this.object[currentKey] === 'object' ) {
                            this.list[j][currentKey] = deepClone( this.object[currentKey] );
                        }else{
                            this.list[j][currentKey] = this.object[currentKey];
                        }
                    }else{
                        // check existing fields and force-refresh them(always as long as this.object[key] does not have a 'value' prop, however we need to keep all parameters up to date)
                        if( typeof this.list[j][currentKey] === 'object' ){
                            if( JSON.stringify( this.list[j][currentKey] ) !== JSON.stringify( this.object[currentKey] ) ){
                                let value;
                                if( typeof this.list[j][currentKey].value === 'object' ){
                                    value = deepClone( this.list[j][currentKey].value );
                                }else{
                                    value = this.list[j][currentKey].value;
                                }

                                this.list[j][currentKey] = deepClone( this.object[currentKey] );
                                this.list[j][currentKey].value = value;
                            }
                        }
                        // separately check sub-list and refresh it if object sample has changed(any depth)
                        if( this.list[j][currentKey].datatype === 'list' ){
                            if( JSON.stringify( this.list[j][currentKey].object ) !== JSON.stringify( this.object[currentKey].object ) ){
                                this.list[j][currentKey].object = deepClone( this.object[currentKey].object );
                            }
                        }
                    }
                }
            }
            // remove old fields if they are not present on object
            for( let j = 0; j < this.list.length; j++ ){
                keys = Object.keys( this.list[j] );
                for( let i = 0; i < keys.length; i++ ){
                    if( !this.object[ keys[i] ] ){
                        delete this.list[ j ][ keys[ i ] ];
                    }
                }
            }
        },
        // assigns local 'list' variable 
        globalListToLocalList(){
            if( !Array.isArray( this.value ) && typeof this.value === 'object' ){
                this.list = deepClone( Object.values( this.value ) );
            }else if( !Array.isArray( this.value ) ){
                this.list = [];
            }else{
                this.list = deepClone( this.value );
            }

            // refresh list objects so they correspond to object sample
            this.updateListElementsFromOjbect();

            //this.listChanged();
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/styles/variables';

    .list {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;

        label{
            display:block;
            width:100%;
            font-size: 1rem;
            margin: 0 0 10px;
            font-weight: bold;
        }

        .list-item{
            width: calc(50% - 15px);
            margin-bottom: 30px;
            border-bottom: 1px solid $light_gray;

            @media screen and (max-width: 1919px){
                width: 100%;
            }

            .list{
                .list-item{
                    width: 100%;
                }
            }

            .list__item-wrapper{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-height: 600px;
                overflow-y:auto;
                padding-right:7.5px;
                margin-right: -7.5px;
            }

            .list__item-wrapper::-webkit-scrollbar{
                width: $scrollbar_width;
            }
            
            .list__item-wrapper::-webkit-scrollbar-track{
                background: $light_gray;
            }
            
            .list__item-wrapper::-webkit-scrollbar-thumb{
                background-color: $focus_color;
            }
        }

        .list-add-item{
            width: 100%;
            ::v-deep .formulate-input[data-classification=button] button {
                background-color: $focus_color;
                border-color: $focus_color;
                transition: .3s;
            }
             ::v-deep .formulate-input[data-classification=button] button:hover{
                 opacity: .8;
             }
        }
    }
</style>
